import React from "react"

import Layout from "../../../components/layout"

const emojiplyPoliticaDePrivacidad = () => (
  <Layout>
    <h1>emojiply: Política de privacidad</h1>

    <p>Leo Picado construyó la aplicación emojiply, como un Servicio, para su distribución y
      consumo de manera gratuita.</p>

    <p>Esta página existe para informarle a sus visitantes sobre los términos que rigen la colección,
      uso y revelación de Información Personal al usar mi Servicio.</p>

    <p>Al elegir usar mi Servicio, usted está aceptando la recolección y uso de información
      regida bajo estos terminos. La Información Personal se usa para proveer y mejorar el Servicio.
      Ninguna de la información colectada será usada o compartida de ninguna manera que no esté
      descrita en esta política de privacidad.</p>

    <p><strong>Recolección y uso de información</strong></p>
    <p>Para poder brindar una mejor experiencia al usar mi Servicio, es posible ser que le solicite
      cierta información que le podría identificar. La misma será almacenada en su dispositivo y
      no tendré acceso a ella de ninguna forma.</p>

    <p><strong>Información de bitácora</strong></p>
    <p>Deseo informarle que cada vez que use mi Servicio, cuando un error llegara a ocurrir
      durante el uso normal de la aplicación, yo recolecto datos e información, mediante de un tercero,
      llamada información de bitácora. La información de bitácora puede incluir datos tales
      como: su dirección IP, nombre del dispositivo, versión del sistem operativo, la configuración
      de la aplicación en el momento de usarla, la hora y fecha en que se usó el servicio y otras
      estadísticas.</p>

    <p><strong>Seguridad</strong></p>
    <p>Yo valoro la confianza que deposita en mi con su Información Personal, así que usamos
      medios comercialmente adecuados para protegerla. Tenga en cuenta que no existe ningún
      medio de transmisión de datos por Internet es 100% seguro y que ningún método de
      almacenamiento es 100% seguro por lo que no puedo garantizar su absoluta seguridad.</p>

    <p>Privacidad de personas menores de 13 años</p>
    <p>El Servicio brindado no está diridigo a ninguna persona menor de 13 años; de ninguna manera
      se solicita o almacena información identificable de cualquier menor de 13 años. En el caso
      de que descubra que un menor de 13 años me ha brindado información personal, inmediatamente
      será eliminada. Si usted es una madre o un padre, una tutora o un tutor y está al tanto
      de que un menor nos ha brindado información persona, le ruego me contacte para llevar
      a cabo las acciones del caso.</p>

    <p><strong>Sobre cambios a esta política de privacidad</strong></p>
    <p>Es posible que nuestra política de privacidad cambie através del tiempo. Dicho lo anterior se le
      recominenda visitar esta página para estar al tanto de los términos que rigen el Servicio.
      Se le notificará de cualquier cambio a la política mediante la actualización de esta página.</p>

    <p>Esta politica entra a regir a partir del 11-13-2020.</p>

    <p><strong>Contacto</strong></p>
    <p>Si tiene dudas o sugerencias sobre mi política de privacidad, no dude en contactarme al
      correo emojiply@leopicado.com.</p>

    <p>Este documento fue generado usando una base provista por <a
      href="https://app-privacy-policy-generator.nisrulz.com/" target="_blank" rel="noopener noreferrer">App Privacy
      Policy Generator</a>.</p>
  </Layout>
)

export default emojiplyPoliticaDePrivacidad
